footer {
    background: linear-gradient(#000d1e, black);;
    padding: 3rem 0;
    text-align: center;
    font-size: 1.6rem;
    margin-top: 8rem;

}

.footer__logo {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: inline-block;
}

.permalinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 3rem;
}

.footer__socials {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 4rem;

}

.footer__socials a {
    background: #14a76c;
    color: white;
    padding: 0.8rem;
    border-radius: 0.7rem;
    display: flex;
    border: 1px solid transparent;
}

.footer__socials a:hover {
    background: black;
    color: white;
    border-color: white;
}

.footer__copyright {
    margin-bottom: 4rem;
    color: rgb(255, 255, 255);
}

/*======================= MEDIA QUERIES (MEDIUM DEVICES) ======================== */
@media screen and (max-width: 1024px) {
    .permalinks {
        flex-direction: column;
        gap: 1.5rem;
    }

    .footer__socials {
        margin-bottom: 2.6rem;

    }
 }
 
 /*========================= MEDIA QUERIES (SMALL DEVICES) =================== */
 @media screen and (max-width: 600px) {
     .container.contact__container {
        width: var(--container-width-sm);
        }
 }