header {
    height: 120vh;
    padding-top: 0rem;
    overflow: hidden;
    


}

.titan__logo {
    
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;


}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
    
    
    
    
}

.legendary__visuals {
    font-size: larger;
}

/* ============== CTA ================== */

.cta {
    margin-top: 10rem;
    margin-bottom: 5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;

}

/* ==================== HEADER SOCIALS ======================== */

/*.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}*/

.banner {

    margin-top: 0px;
    margin-bottom:500px;
}


/* ---------------- SCROLL DOWN ------------------------------ */

.scroll_down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 200;
    font-size: 0.9rem;
}


/*======================= MEDIA QUERIES (MEDIUM DEVICES) ======================== */
@media screen and (max-width: 1024px) {
    header {
        height: 68vh;
    }
}

/*========================= MEDIA QUERIES (SMALL DEVICES) =================== */
@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    .header__socials,
    .scroll__down {
        display: none;
    }
}


/* ================ SHROUD ============== */

.shroud {
    background: linear-gradient(var(--color-primary), transparant);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;

}