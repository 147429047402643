

.portfolio__container {
    display:flex;
    grid-template-columns: repeat(1, 1fr);
    gap: 0.5rem;
    
    
}

.portfolio__item {
    background: transparent;
    padding: 1rem;
    border-radius: 0.5rem;
    border: 7px solid transparent;
    transition: transition 600ms;

}



.portfolio__item-image {
    border-radius: 2em;
    overflow: hidden;
   
    
    
    
}
.text_under_image {
    display: flex;
    grid-template-columns: repeat(1, 1fr);
}
.five {
    padding-top: 2rem;
    height: 50%;
   
}
.phrase__atop_portfolio {
    color: orange;
    padding: 1rem;
    
    
}

.portfolio__item h3 {
    margin: 1.2rem 0 2rem;
    text-align: center;
    color: rgb(255, 255, 255);
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    justify-content: center;
    
}

.portfolio__item-cta:hover {
   
}


/*======================= MEDIA QUERIES (MEDIUM DEVICES) ======================== */
@media screen and (max-width: 1024px) {
   .portfolio__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
   }
}

/*========================= MEDIA QUERIES (SMALL DEVICES) =================== */
@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}
