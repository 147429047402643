.container.contact__container {
    width: 70%;
    display: block;
    grid-template-columns: 30% 58%;
    gap: 12%;

}


.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    place-items: center;
}

.contact__option {
    background: transparent;
    padding: 2rem;
    border-radius: .5rem;
    text-align: center;
    border: 1px solid transparent;
    transition: 600ms;
    border-color: orange;
   

}

.contact__option:hover {
    background-color: transparent; 
    border-color: teal;
    
}


.contact__option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}


.contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
    place-items: center;
   


}

/* ======================= FORM ============================== */

form {
    padding-top:50px;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    width: 50%;
    align-items: center;
}

input,
textarea {
    width: 50%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary);
    resize: none;
    color: white;
    
    
}

/*======================= MEDIA QUERIES (MEDIUM DEVICES) ======================== */
@media screen and (max-width: 1024px) {
   .container.contact__container {
    grid-template-columns: 1fr;
    gap: 2rem;
   }
}

/*========================= MEDIA QUERIES (SMALL DEVICES) =================== */
@media screen and (max-width: 600px) {
    .container.contact__container {
       width: var(--container-width-sm);
       }
}