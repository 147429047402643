.container.testimonials__container {
    width: 20%;
    
    

}

.client__avatar {
    width: 10rem;
    
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.2rem solid rgb(19, 18, 18);
    

}

.testimonial {
    background: transparent;
    text-align: center;
    padding: 2rem;
    user-select: none;
    border-radius: .5rem;
    border: 1px solid transparent;
    border-color: rgb(36, 36, 36);
    border-width: 0;
    
}

.fiverr__best_seller {
    color: orange;
}
.client__review {
    color: var(--color-light);
    font-weight: 500;
    display: block;
    width: 80%;
    margin: 0.8rem auto 0;
}

.review__name {
    color: rgb(24, 24, 24);
    font-weight: 500 !important;
}

.review__text_egobetter {}

/*======================= MEDIA QUERIES (MEDIUM DEVICES) ======================== */
@media screen and (max-width: 1024px) {
    .container.testimonials__container{
        width: 60%;
    }

 }
 
 /*========================= MEDIA QUERIES (SMALL DEVICES) =================== */
 @media screen and (max-width: 600px) {
    .container.testimonials__container{
        width: var(--container-width-sm);
    }

    .client__review {
        width: var(--container-width-sm);
    }

 }
 

 