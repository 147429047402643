@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,600;1,500&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-bg: #1f1f38;
    --color-bg-variant: #2c2c6c;
    --color-primary: #4db5ff;
    --color-primary-variant: rgba(77, 181, 255, 0.4);
    --color-white: #fff;
    --color-light: white;
    --color-warm: #FF6600;
    --color-warm: #ff6600;
    --color-nice-grey: rgb(73, 72, 72);

    --transition: 800ms ease;

    --container-width-lg: 75%; 
    --container-width-md: 86%;
    --container-width-sm: 90%;

}

.backgroundcurve1 {
    background: grey;
}

.backgroundcurve2 {
    background: orange;
}

.backgroundcurve3 {
    background: lightcoral;
}
html {

    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {

    font-family: 'Poppins', sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
    background-image: url(../src/assets/22.jpg);
    background-repeat: repeat;
    
}


/* ============= GENERAL STYLES -------------- */
.container {
    width: var(--container-width-lg);
    margin: 0 auto;
    margin-top: 200px;
}


h1, 
h2, 
h3, 
h4, 
h5 {   
    font-weight: 1000;
    color: white;
    
}

h1 {
    font-size: 4rem;
}

h5 {
    font-size: 1.5rem;
}

h4 {
    font-size: 2rem;
}

h6 {
    font-size: 3rem;
    
}

h7 {
    font-size: 10rem;
}
section {
    margin-top: 0.1rem;
    height: auto;
}


section > h2, section > h5 {

    text-align: center;
    color: var(--color-light);
}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light {
    color: var(--color-light);
    transition: var(--transition);
}

a {
    color: var(--color-white);
    transition: 600ms;
}

a:hover {
    color: var(--color-primary);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 2px solid var(--color-bg-variant);
    transition: var(transition);
}

.btn:hover {
    background: #272727;
    color: white;
    border-color: white;
    
}
                              
.btn-primary {
    background: orange;
    color: var(--color-bg);
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/* -------------- MEDIA QUERIES (MEDIUM DEVICES) ===================== */

@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }

    section {
        margin-top: 6rem;
    
    }
}

/* -------------- MEDIA QUERIES (SMALL DEVICES) ===================== */
@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
    }

    section > h2 {
        margin-top: 2rem;
    }
}
